import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { getListPageURL, getSlug } from '../utils/url'

const ListList = () => {
	const data = useStaticQuery(graphql`
		query Lists {
			allList: sanitySiteSettings {
				productLists {
					slug {
						current
					}
					title
				}
			}
		}
	`)
	return (
		<Layout>
			<SEO title="Listas" />
			<h1 className="uk-heading-line">Listas</h1>
			<ul className="uk-list">
				{data.allList.productLists.map((list) => (
					<li key={getSlug(list)}>
						<Link className="uk-link-text" to={getListPageURL(getSlug(list))}>
							{list.title}
						</Link>
					</li>
				))}
			</ul>
		</Layout>
	)
}

export default ListList
